<template>
    <b-table v-if="tableData" hover :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- id -->
      <template #cell(id)="data">
        <div class="font-weight-bolder">
          {{ data.item.id }}
        </div>
      </template>

      <!-- name -->
      <template #cell(name)="data">
        <router-link :to="'/role/'+data.item.id">{{ data.item.name }}</router-link>
      </template>

      
    </b-table>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BDropdown,
  BTooltip,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BDropdown,
    BTooltip,
    BDropdownItem,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "NAME" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
