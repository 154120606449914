<template>
  <section id="exercises">
    <!-- Exercises Table Card -->
    <b-card no-body>
      <!-- Table Top -->
      <b-row>
        <b-col cols="12" md="12">
          <div class="d-flex align-items-center justify-content-end mt-2 mr-2">
            <b-button variant="primary" @click="updatePermissions">
              <feather-icon icon="RefreshCwIcon" class="mr-25" />
              <span class="text-nowrap">Update permissions</span>
            </b-button>
            <b-button variant="primary" to="/role" style="margin-left:1em;">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span class="text-nowrap">Add role</span>
            </b-button>
          </div>
        </b-col>
      </b-row>            
      <roles-table :table-data="data.roles" class="mt-2 mt-md-2" />
    </b-card>
    <!--/ Exercises Table Card -->
  </section>
</template>



<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import RolesTable from "./RolesTable.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

import navMenuItems from '@/navigation/vertical'

export default {
  data() {
    return {
      data: {
        roles: [
          // {
          //   id: 1,
          //   name: "Shoulder RSI exercises",
          // },
          // {
          //   id: 2,
          //   name: "Frozen shoulder exercises",
          // },
        ],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    vSelect,
    Cleave,
    RolesTable,
  },
  directives: {
    Ripple,
  },
  methods: {
    async loadRoles() {
      const res = await this.callApi('get', '/roles');
        this.data.roles = res.data.result;
    },
    async updatePermissions() {
      const res = await this.callApi('post', '/permissions/updatePermissions', {
        pages:navMenuItems,
        customPermissions:process.env.VUE_APP_CUSTOM_PERMISSIONS.split(';')
      });
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Permissions updated',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.dobSelected = ctx.selectedYMD;
    },
  },
  mounted() {
    this.loadRoles();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.col-form-label {
  min-height: 2.45rem;
}
</style>