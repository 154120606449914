export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Algorithm',
  }, 
  // {
  //   title: 'Settings',
  //   icon: 'SettingsIcon',
  //   //tag: '2',
  //   //tagVariant: 'light-warning',
  //   children: [
      {
        title: 'Urgency levels',
        route: 'urgency-levels',
        icon: 'AlertTriangleIcon',
      },
      {
        title: 'Advices',
        route: 'advices',
        icon: 'MessageSquareIcon',
      },
      {
        title: 'Exercises',
        route: 'exercises',
        icon: 'PlayIcon',
      },
      {
        title: 'Triage rules',
        route: 'triage-rule-versions',
        icon: 'SlidersIcon',
      },  
      {
        title: 'Conditions',
        route: 'conditions',
        icon: 'InfoIcon',
      },  
      // {
      //   title: 'Triage rule versions',
      //   route: 'triage-rule-versions',
      //   icon: 'GitBranchIcon',
      // },  
  //   ],
  // },
  {
    header: 'Results & patients',
  }, 
  {
    title: 'Results',
    route: 'results',
    icon: 'BarChart2Icon',
  },  
  {
    title: 'Patients',
    route: 'patients',
    icon: 'UsersIcon',
  },
  {
    header: 'Access',
  },   
  // {
  //   title: 'Access',
  //   icon: 'ShieldIcon',
  //   //tag: '2',
  //   //tagVariant: 'light-warning',
  //   children: [
      {
        title: 'Organizations',
        route: 'organizations',
        icon: 'UsersIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'UserCheckIcon',
      },
      {
        title: 'User roles',
        route: 'roles',
        icon: 'ListIcon',
      },
  //   ],
  // },
]



